<template>
    <div class="table-responsive mt-3">
        <table class="table table-bordered m-0 bordered">
            <thead>
                <tr>
                    <!-- <th scope="col">#</th> -->
                    <th>Checked</th>
                    <th>sku</th>
                    <th>Product Desc</th>
                    <th>Indiv. price</th>
                    <th>Total Ex Vat</th>
                    <th>Total Inc Vat</th>
                    <th>Quantity</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in selected_order_details.products" :key="item.sku" class="avoid-inside-page-break">
                    <!-- <th scope="row">1</th> -->
                    <td>
                        <b-form-checkbox name="checkbox-1" value="accepted" unchecked-value="not_accepted"> </b-form-checkbox>
                    </td>
                    <td>
                        {{ item.sku }}
                    </td>

                    <td>
                        {{ item.name }}
                    </td>

                    <td>
                        {{ item.price }}
                    </td>

                    <td>
                        {{ item.pivot.quantity * item.price }}
                    </td>
                    <td>
                        {{ (item.pivot.quantity * item.price * 1.15).toFixed(2) }}
                    </td>
                    <td>
                        {{ item.pivot.quantity }}
                    </td>
                    <!-- <div class="page-break"></div> -->
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { orderComputed, orderMethods } from "@/state/helpers";

export default {
    data: () => ({}),
    computed: {
        ...orderComputed,
    },
    methods: {
        ...orderMethods,
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.page-break {
    display: block;
    page-break-before: always;
}
.avoid-inside-page-break {
    page-break-inside: avoid;
}
</style>
