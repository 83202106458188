<template>
    <div>
        <b-card>
            <div class="d-flex flex-wrap justify-content-between mt-2">
                <div>
                    <button type="button" class="btn btn-primary mb-2 mr-2 float-left" @click="resetOrderData">
                        <i class="mdi mdi-arrow-left mr-1"></i> Go back
                    </button>
                </div>
                <div v-if="(loggedIn_user_role == 1 || loggedIn_user_role == 2) && hasMounted">
                    <b-button-group class="mb-2">
                        <b-button variant="secondary" @click="printPDF('printCustomerLabel')">
                            <i class="mdi mdi-printer mr-1"></i> Customer Label
                        </b-button>
                        <b-button class="ml-2" variant="secondary" @click="printPDF('printOrder')">
                            <i class="mdi mdi-printer mr-1"></i> Quality Control Form
                        </b-button>
                    </b-button-group>
                </div>
            </div>
        </b-card>

        <b-card v-if="hasMounted" style="min-height: 400px">
            <!-- these compoennts are for printing -->
            <printCustomerLabel v-show="show_printCustomerLabel" />

            <printQualityControlForm v-show="show_printQualityControlForm" />
            <!-- end of printing -->

            <orderTracking v-if="hasMounted" :order_data="selected_order_details" :read_only="!canEditContent" />
        </b-card>

        <!-- <b-overlay no-wrap :show="!hasMounted"></b-overlay> -->
    </div>
</template>

<script>
import OrderTracking from "../ext/track-order.vue";
import { adminComputed, orderComputed, orderMethods } from "@/state/helpers";

//eslint-disable-next-line
import printJS from "print-js";
import * as html2pdf from "../../plugins/html2pdf/html2pdf.bundle.min";
import printCustomerLabel from "./print/customerLabel.vue";
import printQualityControlForm from "./print/qualityControlForm.vue";

export default {
    components: {
        OrderTracking,
        printCustomerLabel,
        printQualityControlForm,
    },
    data: () => ({
        hasMounted: false,
        show_modal: false,
        edit_property_id: -1,
        //these are for the v-show for the printing components
        show_printCustomerLabel: false,
        show_printQualityControlForm: false,
    }),
    computed: {
        ...adminComputed,
        ...orderComputed,
        canEditContent() {
            if (this.user_role == "admin" || this.user_role == "production") {
                return true;
            }
            return false;
        },
    },
    methods: {
        ...orderMethods,
        resetOrderData() {
            // this.$router.push({ path: "/clients" });
            //go back to prev route
            this.$router.go(-1);
        },
        isOrderLoaded() {
            // check if no order data exists OR id old order data != the selected order id
            if (this.selected_order_details == null) {
                return false;
            }

            return this.selected_order_details.id == this.selected_order_id;
        },
        //both printing buttons will use this method
        printPDF(elementID) {
            const element = document.getElementById(elementID);
            if (!element) {
                //eslint-disable-next-line
                console.error("Element not found");
                return;
            }
            //will v-show on the screen so that html2pdf can "see" the content to print, we set to false after the pdf is generated and passed to printJS
            elementID == "printCustomerLabel" ? (this.show_printCustomerLabel = true) : (this.show_printQualityControlForm = true);

            const options = {
                margin: [10, 10, 10, 10], // top, left, bottom, right
                //this will be the file name
                filename: "exported.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2, logging: true },
                jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            };
            // Extend the options object with the didDrawPage hook
            // options.jsPDF.autoPaging = true;
            // options.jsPDF.didDrawPage = (pdf) => {
            //     // If the current position (pdf.y) is beyond the page's available height,
            //     // insert a page break and reset the y position
            //     if (pdf.y > pdf.internal.pageSize.height - options.margin[2]) {
            //         pdf.addPage();
            //         pdf.y = options.margin[0];
            //     }
            // };

            if (typeof window.chrome !== "undefined") {
                this.$nextTick(() => {
                    html2pdf()
                        .set(options)
                        .from(element)
                        .outputPdf("blob")
                        .then(function (pdfBlob) {
                            const url = URL.createObjectURL(pdfBlob);
                            printJS({
                                printable: url,
                                type: "pdf",
                            });
                        })
                        .catch(function (error) {
                            //eslint-disable-next-line
                            console.error("PDF generation error:", error);
                        });

                    //set so that v-show will be false
                    elementID == "printCustomerLabel" ? (this.show_printCustomerLabel = false) : (this.show_printQualityControlForm = false);
                });
            } else {
                //all other browsers will use this
                this.$nextTick(() => {
                    html2pdf()
                        .set(options)
                        .from(element)
                        .outputPdf("datauristring")
                        .then(function (pdfDataUri) {
                            const win = window.open(pdfDataUri, "_blank");
                            if (!win) {
                                //eslint-disable-next-line
                                console.error("Failed to open window");
                                return;
                            }
                        })
                        .catch(function (error) {
                            //eslint-disable-next-line
                            console.error("PDF generation error:", error);
                        });

                    //set so that v-show will be false
                    elementID == "printCustomerLabel" ? (this.show_printCustomerLabel = false) : (this.show_printQualityControlForm = false);
                });
            }
        },
    },

    mounted() {
        if (this.isOrderLoaded()) {
            this.hasMounted = true;
        } else {
            this.getOrderDetails(this.$route.params.orderid).then(() => {
                this.hasMounted = true;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.propertyContainer {
    display: flex;
    flex-flow: row wrap;
}
.propertyTile {
    /*background: white;*/
    margin: 0.25rem 0.25rem 0.35rem 0.25rem;
    height: 2.5rem;
    /*width: calc( (100% / 3) - 20px );*/
    width: 12rem;
    padding: 0.5rem;

    /* allow floating top right icon */
    position: relative;
}
.page-break {
    display: block;
    page-break-before: always;
}
.avoid-page-break {
    display: table;
    width: 100%;
}
.avoid-page-break tbody {
    display: table-row-group;
}
.avoid-inside-page-break {
    page-break-inside: avoid;
}
</style>
