<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import orderDetails from "@/components/orders/ordersDetails.vue";

/**
 * Starter component
 */
export default {
    page: {
        title: "Client Order",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, orderDetails },
    data() {
        return {
            title: "Client Order",
            items: [
                {
                    text: "Client Order",
                    active: true,
                },
            ],
        };
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <orderDetails />
    </Layout>
</template>
