var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"id":"printCustomerLabel"}},[_c('b-row',{staticClass:"mb-3 d-flex justify-content-between"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6 mr-5"}},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v("FROM:")]),_c('h5',[_vm._v(" "+_vm._s(_vm.core.site_config.company_name)+" ")]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.selected_order_details.branch.address))]),_c('span',[_vm._v(_vm._s(_vm.selected_order_details.branch.name))]),_c('span',[_vm._v(_vm._s(_vm.selected_order_details.branch.contact_no))])]),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Order Number","label-for":"person-input"}},[_c('b-form-input',{attrs:{"size":"sm","disabled":"","type":"text"},model:{value:(_vm.selected_order_details.order_no),callback:function ($$v) {_vm.$set(_vm.selected_order_details, "order_no", $$v)},expression:"selected_order_details.order_no"}})],1)],1),_c('b-col',{staticClass:"text-center mt-3",attrs:{"cols":"3"}},[_c('img',{attrs:{"src":require("@/assets/images/darkroots_web.webp"),"alt":"","height":"125"}})])],1)],1)],1),_c('hr'),_c('h2',{staticClass:"font-weight-bold"},[_vm._v("DELIVER TO:")]),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Company name","label-for":"person-input"}},[_c('b-form-input',{attrs:{"size":"sm","disabled":"","type":"text"},model:{value:(
                                _vm.selected_order_details.client.company_name
                            ),callback:function ($$v) {_vm.$set(_vm.selected_order_details.client, "company_name", $$v)},expression:"\n                                selected_order_details.client.company_name\n                            "}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"person-input"}},[_c('b-form-input',{attrs:{"size":"sm","disabled":"","type":"text"},model:{value:(
                                _vm.selected_order_details.client.contact_no
                            ),callback:function ($$v) {_vm.$set(_vm.selected_order_details.client, "contact_no", $$v)},expression:"\n                                selected_order_details.client.contact_no\n                            "}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Contact Person","label-for":"person-input"}},[_c('b-form-input',{attrs:{"size":"sm","disabled":"","type":"text"},model:{value:(
                                _vm.selected_order_details.client.contact_person
                            ),callback:function ($$v) {_vm.$set(_vm.selected_order_details.client, "contact_person", $$v)},expression:"\n                                selected_order_details.client.contact_person\n                            "}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"person-input"}},[_c('b-form-input',{attrs:{"size":"sm","disabled":"","type":"text"},model:{value:(_vm.selected_order_details.client.emails),callback:function ($$v) {_vm.$set(_vm.selected_order_details.client, "emails", $$v)},expression:"selected_order_details.client.emails"}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('b-form-group',{attrs:{"label":"Address","label-for":"person-input"}},[_c('b-form-input',{attrs:{"size":"sm","disabled":"","type":"text"},model:{value:(_vm.selected_order_details.client.address),callback:function ($$v) {_vm.$set(_vm.selected_order_details.client, "address", $$v)},expression:"selected_order_details.client.address"}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"person-input"}},[_c('b-form-input',{attrs:{"size":"sm","disabled":"","type":"text"},model:{value:(_vm.selected_order_details.client.city),callback:function ($$v) {_vm.$set(_vm.selected_order_details.client, "city", $$v)},expression:"selected_order_details.client.city"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Postal Code","label-for":"person-input"}},[_c('b-form-input',{attrs:{"size":"sm","disabled":"","type":"text"},model:{value:(
                                _vm.selected_order_details.client.postal_code
                            ),callback:function ($$v) {_vm.$set(_vm.selected_order_details.client, "postal_code", $$v)},expression:"\n                                selected_order_details.client.postal_code\n                            "}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Province","label-for":"person-input"}},[_c('b-form-input',{attrs:{"size":"sm","disabled":"","type":"text"},model:{value:(_vm.selected_order_details.client.province),callback:function ($$v) {_vm.$set(_vm.selected_order_details.client, "province", $$v)},expression:"selected_order_details.client.province"}})],1)],1)],1)],1)],1),_c('hr')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }